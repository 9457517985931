<template>
  <div class="chinese">
    <div class="bread">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/academic' }"
          >机构订阅</el-breadcrumb-item
        >
        <el-breadcrumb-item>期刊推荐</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="box">
      <div class="header">
        <h2>期刊检索：</h2>
        <div class="input">
          <el-input
            v-model="searchInput"
            placeholder="请输入检索字段"
            clearable
          >
            <el-select v-model="select" slot="prepend" placeholder="请选择">
              <el-option label="刊名" value="name"></el-option>
              <el-option label="ISSN" value="issn"></el-option>
              <el-option label="篇名" value="title"></el-option>
              <el-option label="DOI" value="doi"></el-option> </el-select
          ></el-input>
          <el-button size="" type="primary" round @click="onSubmit"
            >搜索</el-button
          >
        </div>
      </div>
      <div class="search_field">
        <!-- <div class="sifts">
          <div style="width: 100px; line-height: 38px">期刊语种：</div>
          <div class="choose">
            <el-button
              :class="item.case == 1 ? 'chosen' : 'bgcolor'"
              round
              size="mini"
              v-for="(item, index) in type"
              :key="index"
              @click="search_type(item, index)"
              >{{ item.book_type }}</el-button
            >
          </div>
        </div> -->
        <!-- <div class="sifts">
          <div style="width: 100px; line-height: 38px">期刊类别：</div>
          <div class="choose">
            <el-button
              :class="item.state == 1 ? 'chosen' : 'bgcolor'"
              round
              size="mini"
              v-for="(item, index) in states"
              :key="index"
              @click="search_states(item, index)"
              >{{ item.categories }}</el-button
            >
          </div>
        </div> -->
      </div>
      <el-divider></el-divider>
      <div v-if="journalOr && !journalSearchList">
        <div v-if="comprehensive.length == 0" class="not01">
          <img src="../../../assets/imgs/zanwushuj.png" alt="" />
        </div>
        <div class="list" v-for="(item, index) in comprehensive" :key="index">
          <div class="title">
            <div>
              <h2 style="">{{ item.name }}：</h2>
            </div>
            <router-link
              :to="{
                path: '/journalsList',
                query: { subject: item.name, language: language , webPage:4},
              }"
              ><span>更多>></span></router-link
            >
          </div>

          <div class="journals_list">
            <el-carousel
              indicator-position="outside"
              :autoplay="false"
              arrow="always"
            >
              <el-carousel-item
                v-for="(item_data, index) in item.data"
                :key="index"
              >
                <div class="cover_imgs">
                  <div
                    v-for="(item_single, index) in item_data"
                    :key="'single' + index"
                    class="imgs"
                    @click="goDetail(item_single)"
                  >
                    <div class="imges">
                      <img
                        :src="
                          'https://push.newacademic.net/cover/journal_cover/' +
                          (item_single.language == 1
                            ? item_single.name
                            : item_single.cn_name) +
                          '.jpg'
                        "
                        alt=""
                      />
                      <img
                        class="subscribe_css"
                        src="../../../assets/imgs/icons/customized1.png"
                        alt=""
                      />
                    </div>

                    <h4
                      @click="goDetail(item_single)"
                      class="bookTitle"
                      v-if="item_single.language == '1'"
                    >
                      {{ item_single.name }}
                    </h4>
                    <h4
                      @click="goDetail(item_single)"
                      class="bookTitle"
                      v-if="item_single.language == '0'"
                    >
                      {{ item_single.cn_name }}
                    </h4>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
      <div v-if="journalSearchList">
        <div
          v-for="(item, index) in searchList.slice(
            10 * pag,
            10 * currentPage1 - 1
          )"
          :key="index"
          class="journal_list"
        >
          <div style="display: flex">
            <div class="journal_img">
              <img
                :src="
                  'https://push.newacademic.net/cover/journal_cover/' +
                  (item.language == 1 ? item.name : item.cn_name) +
                  '.jpg'
                "
                alt=""
                @click="goDetail(item)"
              />
              <div class="item" v-if="item.isReads == '1'"></div>
            </div>
            <div class="journal_data">
              <div class="title01">
                <!-- <router-link
                :to="{ path: '/journalsDetail', query: { id: item.id } }"
                v-if="item.language == 1"
                > -->
                <el-tag style="margin: 0 20px 0 0">{{
                  item.categoryType
                }}</el-tag>
                <h2 v-if="item.language == 1" @click="goDetail(item)">
                  {{ item.name }}
                </h2>
                <!-- </router-link
              > -->
                <!-- <router-link
                :to="{
                  path: '/journalsDetail',
                  query: { id: item.id, subject: item.categories },
                }"
                v-if="item.language == 0"
                > -->
                <h2 v-if="item.language == 0" @click="goDetail(item)">
                  {{ item.cn_name }}
                </h2>
                <!-- </router-link
              > -->
              </div>

              <div class="tags">
                <div v-for="(item, index) in item.source" :key="index">
                  <el-tag v-if="item == 'CA 化学文摘(美)(2020)'">CA</el-tag>
                  <el-tag
                    v-if="
                      item == 'CSCD 中国科学引文数据库来源期刊(2021-2022年度)'
                    "
                    type="success"
                    >CSCD</el-tag
                  >
                  <el-tag
                    v-if="
                      item ==
                      'CSCD 中国科学引文数据库来源期刊(2021-2022年度)(扩展版)'
                    "
                    type="info"
                    >CSCD(扩)</el-tag
                  >
                  <el-tag
                    v-if="
                      item == 'CSSCI 中文社会科学引文索引(2021-2022)来源期刊'
                    "
                    type="warning"
                    >CSSCI</el-tag
                  >
                  <el-tag v-if="item == 'CSTPCD'" type="danger">CSTPCD</el-tag>
                  <el-tag
                    v-if="item == 'JST 日本科学技术振兴机构数据库(日)(2018)'"
                    >JST</el-tag
                  >
                  <el-tag
                    v-if="item == 'Pж(AJ) 文摘杂志(俄)(2020)'"
                    type="success"
                    >Pж(AJ)
                  </el-tag>
                  <el-tag v-if="item == 'SA 科学文摘(英)(2020)'" type="info"
                    >SA</el-tag
                  >
                  <el-tag
                    v-if="
                      item == 'WJCI 科技期刊世界影响力指数报告（2020）来源期刊'
                    "
                    type="warning"
                    >WJCI</el-tag
                  >
                  <el-tag v-if="item == '北大核心'" type="danger"
                    >北大核心</el-tag
                  >
                  <el-tag v-if="item == 'EI 工程索引(美)(2020)'" type="warning"
                    >EI</el-tag
                  >
                  <el-tag
                    v-if="item == 'SCI 科学引文索引(美)(2020)'"
                    type="danger"
                    >SCI</el-tag
                  >
                </div>
              </div>
              <p>创办单位：{{ item.press }}</p>
              <p>出版周期：{{ item.frequency }}</p>
              <p>创建时间：{{ item.create_time | parseTime("{y}-{m}-{d}") }}</p>
              <p>
                最新更新时间：{{ item.update_time | parseTime("{y}-{m}-{d}") }}
              </p>
            </div>
          </div>
          <el-divider style="width: 100%"></el-divider>
        </div>

        <div class="page-bar" v-if="totalCount > 10">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="totalCount > 10000 ? 10000 : totalCount"
            @current-change="handleCurrentChange"
            :page-size="10"
            :current-page.sync="currentPage1"
          >
          </el-pagination>
        </div>
      </div>
      <div class="articleList" v-if="!journalOr">
        <div v-if="article.length == 0" class="not">
          <img src="../../../assets/imgs/zanwushuj.png" alt="" />
        </div>
        <div class="article_list" v-for="(item, index) in article" :key="index">
          <div class="title">
            <div>
              <h2
                class="titleH"
                v-if="item._source.title !== '' && item._source.language == '1'"
                @click="goEssay(item)"
              >
                {{ item._source.title }}
              </h2>
              <h2
                v-if="
                  item._source.ch_title !== '' && item._source.language == '0'
                "
                @click="goEssay(item)"
              >
                {{ item._source.ch_title }}
              </h2>
              <h2
                v-if="
                  item._source.ch_title !== '' && item._source.language == '1'
                "
                style="font-size: 15px"
              >
                {{ item._source.ch_title }}
              </h2>
            </div>
            <!-- <div>
              <img
                @click="collectionCollect(item._id)"
                src="../../../assets/imgs/icons/collect1.png"
                alt=""
              />
            </div> -->
          </div>

          <p>
            <!-- <img src="../../../assets/imgs/icons/photo.png" alt="" /> -->
            <span
              v-for="(item_author, index) in item._source.author"
              :key="index"
              >{{ item_author }} ；</span
            >
          </p>
          <p
            v-if="
              item._source.ch_keyword.length > 0 && item._source.language == '0'
            "
          >
            <!-- <img src="../../../assets/imgs/icons/journalsci.png" alt="" /> -->
            <span
              v-for="(item_keyword, index) in item._source.ch_keyword"
              :key="index"
              >{{ item_keyword }} ；</span
            >
          </p>
          <p
            v-if="
              item._source.keyword.length > 0 && item._source.language == '1'
            "
          >
            <!-- <img src="../../../assets/imgs/icons/journalsci.png" alt="" /> -->
            <span
              v-for="(item_keyword, index) in item._source.keyword"
              :key="index"
              >{{ item_keyword }} ；</span
            >
          </p>
          <p>
            <!-- <img src="../../../assets/imgs/icons/journalsci.png" alt="" /> -->
            <span v-if="item._source.journal_name !== ''" style="width: 50%"
              >{{ item._source.journal_name }}
            </span>
            <span style="width: 50%"
              ><span v-if="item._source.year !== ''"
                >{{ item._source.year }}年</span
              ><span v-if="item._source.vol !== ''"
                >第{{ item._source.vol }}卷</span
              ><span v-if="item._source.issue !== ''"
                >第{{ item._source.issue }}卷</span
              ></span
            >
          </p>
          <p
            v-if="
              item._source.ch_abstract.length > 0 &&
              item._source.language == '0'
            "
          >
            <span v-if="item._source.ch_abstract.length > 150 && !more"
              >{{ item._source.ch_abstract.slice(0, 150) }}
              <span class="more_text">...</span></span
            >
          </p>
          <p
            v-if="
              item._source.abstract.length > 0 && item._source.language == '1'
            "
          >
            <span v-if="item._source.abstract.length > 150 && !more"
              >{{ item._source.abstract.slice(0, 150) }}
              <span class="more_text">...</span></span
            >
          </p>
          <p v-if="item._source.ch_abstract.length > 0">
            <span v-if="more"
              >{{ item._source.ch_abstract }}
              <span class="more_text"> 收起</span></span
            >
          </p>
          <!-- @click="moreText" -->
          <el-divider style="margin: 30px 0"></el-divider>
        </div>
        <div class="page-bar" v-if="totalCount01 > 0">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="totalCount01 > 10000 ? 10000 : totalCount01"
            @current-change="handleCurrentChange01"
            :page-size="10"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { discipline_list, journal_list, essay_list } from "@/Api/journals";
import Vue from "vue";
export default {
  data() {
    return {
      Height: 0,
      searchInput: "",
      type: [
        {
          book_type: "中文期刊",
          type: "0",
          case: 1,
        },
        {
          book_type: "英文期刊",
          type: "1",
          case: 0,
        },
      ],
      states: [],
      language: 2,
      comprehensive: [],
      select: "name",
      getList: {
        language: 0,
        field: "",
        val: "",
      },
      essays: {
        page: 1,
        num: 10,
        field: "",
        val: "",
      },
      journalOr: true,
      article: [],
      more: false,
      totalCount01: 0,
      journalSearchList: false,
      searchList: [],
      totalCount: 0,
      currentPage1: 1,
      pag: 0,
    };
  },
  created() {
    // console.log('aaaaa')
    sessionStorage.setItem('academic_num',4)
    if (this.$route.query.search) {
      this.searchInput = this.$route.query.search;
      this.select = this.$route.query.type;
      this.onSubmit();
    } else {
      this.getDiscipline();
      this.getJournalList();
    }
  },
  methods: {
    // 获取学科分类
    getDiscipline() {
      let id = {
        user_openid: localStorage.getItem("code"),
      };

      discipline_list(id)
        .then((res) => {
          // console.log(res);
          this.states = [];
          res.data.data.forEach((item) => {
            if (item.categories == "综合类") {
              Vue.set(item, "state", 1);
            } else {
              Vue.set(item, "state", 0);
            }
            this.states.push(item);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 分割数组
    group(array, subGroupLength) {
      let index = 0;
      let newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, (index += subGroupLength)));
      }
      return newArray;
    },

    // 获取期刊列表
    getJournalList() {
      this.getList = {
        language: this.language,
        field: this.select,
        val: this.searchInput,
      };

      journal_list(this.getList)
        .then((res) => {
        //   console.log(res);
          this.comprehensive = [];
          for (var i in res.data.data) {
            // console.log(i, ":", res.data.data[i]);
            if (res.data.data[i].length > 0) {
              let types = {
                name: i,
                data: this.group(res.data.data[i], 5),
              };
              this.comprehensive.push(types);
            }
          }
        //   console.log(this.comprehensive);
          this.searchList = [];
          if (this.journalSearchList) {
            for (var i in res.data.data) {
              // console.log(i, ":", res.data.data[i]);
              if (res.data.data[i].length > 0) {
                res.data.data[i].forEach((item) => {
                  Vue.set(item, "categoryType", i);
                  this.searchList.push(item);
                });
                this.totalCount = this.searchList.length;
              }
            }
            // console.log(this.searchList);
          }
          // this.comprehensive = res.data.data["综合类"];
          // this.comprehensive = this.group(this.comprehensive, 5);
          // console.log(this.comprehensive);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getEssayList() {
      essay_list(this.essays)
        .then((res) => {
        //   console.log(res);
          this.article = res.data.data.hits;
          this.totalCount01 = res.data.data.total.value;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    moreText() {
      this.more = !this.more;
    },
    // 检索期刊
    onSubmit() {
      if (this.select == "name" || this.select == "issn") {
        this.journalOr = true;
        this.getList = {
          language: this.language,
          field: this.select,
          val: this.searchInput,
        };
        // console.log(this.getList);
        if (this.searchInput !== "") {
          this.journalSearchList = true;
        } else {
          this.journalSearchList = false;
        }

        this.journalOr = true;
        this.getJournalList();
      } else {
        this.essays = {
          page: 1,
          num: 10,
          field: this.select,
          val: this.searchInput,
        };
        this.journalOr = false;
        this.journalSearchList = false;
        this.getEssayList();
      }
    },
    handleCurrentChange(val) {
      this.pag = val - 1;
      this.currentPage1 = val;
      this.getJournalList();
      //   window.scrollTo(0, 0);
    },
    // 翻页
    handleCurrentChange01(val) {
      this.essays.page = val;
      this.getEssayList();
      //   window.scrollTo(0, 0);
    },
    // 语种
    search_type(item, i) {
      if (this.language !== i) {
        if (item.book_type == "中文期刊") {
          this.type[0].case = 1;
          this.type[1].case = 0;
          this.language = 0;
        } else {
          this.type[1].case = 1;
          this.type[0].case = 0;
          this.language = 1;
        }
        this.getJournalList();
      }
    },
    goEssay(data) {
      if (data._source.language == "0") {
        this.$router.push({
          path: "/essay",
          query: {
            id: data._id,
            search: this.searchInput,
            title: data._source.ch_title,
            type: this.select,
            webPage: 4 
          },
        });
      } else {
        this.$router.push({
          path: "/essay",
          query: {
            id: data._id,
            search: this.searchInput,
            title: data._source.title,
            type: this.select,
            webPage: 4 
          },
        });
      }
    },
    // 学科分类
    search_states(item, i) {
      if (this.states[i].state == 0) {
        this.states[i].state = 1;
      } else {
        this.states[i].state = 0;
        // let index = this.condition.indexOf(item.status);
        // if (index > -1) {
        //   //大于0 代表存在，
        //   this.condition.splice(index, 1); //存在就删除
        // }
      }
      // console.log(this.condition);
    },
    // 去期刊详情
    goDetail(row) {
    //   console.log(row);

      if (this.journalSearchList) {
        this.$router.push({
          name: "JournalsDetail",
          query: {
            id: row.id,
            subject: row.categories,
            personalized: row.personalized,
            type: this.select,
            search: this.searchInput,
             webPage: 4
          },
        });
      } else {
        this.$router.push({
          name: "JournalsDetail",
          query: {
            id: row.id,
            subject: row.categories,
            personalized: row.personalized,
             webPage: 4 
          },
        });
      }
    },
  },
};
</script>

<style  lang="less" scoped>
.chinese {
  background: white;
  .bread {
    // line-height: 50px;
    min-width: 1200px;
    max-width: 1550px;
    margin: auto;
    padding: 30px 0 0 60px;
  }
  .box {
    min-width: 1200px;
    max-width: 1550px;
    margin: auto;
    padding: 30px 10%;
    min-height: 600px;
    .header {
      display: flex;
      // justify-content: space-between;
      width: 80%;
      min-width: 600px;
      height: 50px;
      line-height: 50px;
      h2 {
        font-size: 18px;
        // border-left: rgb(243, 102, 102) 3px solid;
        // padding-left: 5px;
      }
      .input {
        // width: 380px;
        line-height: 50px;
        .el-input {
          width: 375px;
          margin-right: 10px;
          /deep/ .el-input-group__prepend {
            width: 80px;
            .el-select {
              /deep/ .el-input {
              }
            }
          }

          /deep/ .el-input__inner {
            // height: 30px;
            // line-height: 30px;
            // border-radius: 15px;
          }
        }
      }
      .el-button--mini {
        width: 90px;
      }
    }
    .search_field {
      .sifts {
        display: flex;
        margin: 15px 0;
        font-weight: 600;
        .choose {
          width: 800px;
          line-height: 38px;
        }
        .el-button--mini {
          width: 90px;
          margin: 0 10px 10px 0;
        }
        span {
          font-weight: normal;
        }
        .bgcolor {
          background-color: #f5f3ff;
          color: #aaa;
        }
        .chosen {
          // border: 1px solid #806fde;
          // background: none;
          color: #ffffff;
          background-image: linear-gradient(to bottom, #B14A75, #B14A75);
        }
        .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
          width: 125px;
          margin: 0 20px 0 10px;
        }
        /deep/ .el-input__inner {
          border-radius: 14px;
          // background-color: #f5f3ff;
          background-image: linear-gradient(to bottom, #B14A75, #B14A75);
          color: rgb(255, 255, 255);
        }
      }
    }
  }
}
.el-carousel__item .journals_list {
  height: 230px;
  // margin: 0;
}

.el-carousel__item:nth-child(2n) {
  // background-color: #d3dce6;
}

.el-carousel__item:nth-child(2n + 1) {
  // background-color: #d3dce6;
}

.journals_list {
  .el-carousel {
    /deep/ .el-carousel__container {
      height: 280px;
      border-radius: 10px;
      .el-carousel__item {
        // border-radius: 10px;
      }
    }
  }
  .cover_imgs {
    padding: 0 50px;
    display: flex;
    justify-content: space-around;
    .imgs {
      margin: 30px 0;
      .imges{
          display: flex;
      }
      img {
        width: 150px;
        height: 200px;cursor: pointer;
      }
    }
  }
}
.title {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  span {
    line-height: 21px;
  }
  span:hover {
    color: #4ccdf5;
  }
}
.bookTitle {
  width: 150px;
  margin: 5px 0;
  line-height: 20px;
  // display: inline-block;
  word-break: break-all;
  word-wrap: break-word;
}
.bookTitle:hover {
  color: #c0c0c0;
  cursor: pointer;
}
.articleList {
  // padding: 0 2%;
  .article_list {
    //   margin: 20px 0;
    margin-bottom: 10px;
    // padding: 10px;
    border-radius: 5px;
    // background: #eeeeee;
    .title {
      width: 100%;
      // min-width: 300px;
      display: flex;

      justify-content: space-between;
      img {
        width: 30;
        cursor: pointer;
      }
      h2 {
        line-height: 32px;
        cursor: pointer;
      }
      img {
        width: 30px;
        cursor: pointer;
      }
    }
    h2 {
      font-size: 18px;
      font-weight: 500;
    }
    p {
      line-height: 30px;
      margin: 10px;
      word-wrap: break-word;
      img {
        width: 30px;
        position: relative;
        top: 10px;
        margin-right: 10px;
      }
      span {
        display: inline-block;
        line-height: 30px;
        //   text-indent:2em;
      }
      .more_text {
        font-weight: 700;
      }
      .more_text:hover {
        color: #9be0fc;
        cursor: pointer;
      }
    }
    .abstract {
      text-indent: 2em;
      overflow: hidden;
      -webkit-line-clamp: 2.5;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
  .page-bar {
    margin: 10px 0;
    display: flex;
    justify-content: center;
  }
}
.page-bar {
  padding: 10px 0;
  display: flex;
  justify-content: center;
}
.titleH:hover {
  color: #a0a0a0;
  cursor: pointer;
}
.not {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 450px;
    height: 400px;
  }
}
.not01 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100px;
  img {
    width: 450px;
    height: 400px;
  }
}
.journal_list {
  margin: 20px 0;

  // padding: 0 10%;
  // justify-content: space-between;
  .journal_img {
    margin-right: 50px;
    position: relative;
    img {
      width: 150px;
      height: 205px;
      border-radius: 5px;
    }
    .item {
      width: 16px;
      height: 16px;
      background: red;
      border-radius: 8px;
      position: relative;
      top: -215px;
      left: 165px;
    }
  }
  .journal_data {
    width: 100%;
    //  max-width: 800px;
    // min-width: 500px;
    .title01 {
      width: 100%;
      // min-width: 300px;
      display: flex;
      // justify-content: space-between;
    }
    h2 {
      line-height: 32px;
    }
    h2:hover {
      color: #a0a0a0;
      cursor: pointer;
    }
    .tags {
      display: flex;
      margin: 10px 0;
      .el-tag {
        margin-right: 10px;
      }
    }
    p {
      line-height: 30px;
    }
  }
}
.subscribe_css {
  width: 50px !important;
  height: 50px !important;
  position: relative;
  left: -50px;
//   top: -150px;
}
</style>